<template>
<s-header v-if="$route.params.id=='student'" :name='"-考生登录"'></s-header>
<s-header v-if="$route.params.id=='school'" :name='"-主考登录"'></s-header>
<s-header v-if="$route.params.id=='clbum'" :name='"-班主任登录"'></s-header>
<s-header v-if="$route.params.id=='4'" :name='"-画室检索/查验"'></s-header>
<!-- 考生登录 -->
<div style="margin: 10px;">
    <van-tabs v-if="$route.params.id=='student'" v-model:active="active">
    <van-tab title="未注册考生登录">
      <van-form @submit="onLogin">
        <van-cell-group inset>
          <van-field
            v-model="state.wusername"
            name="code"
            label="考点代码"
            type="number"
            placeholder="请输入考点代码"
            :rules="[{ required: true, message: '请输入考点代码' }]"
          />
          <van-field
            v-model="state.wpassword"
            type="password"
            name="password"
            label="班级注册码"
            placeholder="请输入班级注册码"
            :rules="[{ required: true, message: '请输入班级注册码' }]"
          />
        <van-field name="identity"  v-model="state.identity" label="身份" v-show="false" />
        <van-field name="reg"  v-model="state.reg0" label="状态" v-show="false" />

        
        </van-cell-group>
        <div style="margin: 10px;">
          <van-button plain   block type="primary" native-type="submit">
            <span style="color:#555555;">点击登录</span>
          </van-button>
        </div>
      </van-form>
      <!-- <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        技术是开发它的人的共同灵魂。
      </van-notice-bar> -->
    </van-tab>
    <van-tab title="已注册考生登录">
      <van-form @submit="onLogin">
        <van-cell-group inset>
          <van-field
            v-model="state.username"
            name="code"
            label="报名序号"
            type="number"
            placeholder="请输入报名序号"
            :rules="[{ required: true, message: '请输入报名序号' }]"
          />
          <van-field
            v-model="state.password"
            type="password"
            name="password"
            label="考生自设密码"
            placeholder="请输入考生自设密码"
            :rules="[{ required: true, message: '请输入考生自设密码' }]"
          />
        <van-field name="identity"  v-model="state.identity" label="身份" v-show="false" />
        <van-field name="reg"  v-model="state.reg1" label="状态" v-show="false" />
        </van-cell-group>
        <div style="margin: 10px;">
          <van-button plain   block type="primary" native-type="submit">
            <span style="color:#555555;">点击登录</span>
          </van-button>
        </div>
      </van-form>
    </van-tab>
  </van-tabs>
   <!-- <van-button plain block type="primary" native-type="submit" @click="state.showStudent = true">
      <span style="color:#555555;">未注册考生登录</span>
    </van-button> -->
    <!-- <van-button plain block type="primary" native-type="submit">
      <span style="color:#555555;">已注册考生登录</span>
    </van-button> -->
</div>
<!-- 主考登录 -->
<van-form v-if="$route.params.id=='school'" @submit="onLogin">
  <van-cell-group inset>
    <van-field
      v-model="state.username"
      name="code"
      label="考点代码"
      type="number"
      placeholder="请输入考点代码"
      :rules="[{ required: true, message: '请输入考点代码' }]"
    />
    <van-field
      v-model="state.password"
      type="password"
      name="password"
      label="考点激活码"
      placeholder="请输入考点激活码"
      :rules="[{ required: true, message: '请输入考点激活码' }]"
    />
  <van-field name="identity"  v-model="state.identity" label="身份" v-show="false" />
  </van-cell-group>
  <div style="margin: 10px;">
    <van-button plain   block type="primary" native-type="submit">
      <span style="color:#555555;">点击登录</span>
    </van-button>
  </div>
</van-form>
<!-- 班主任登录 -->
<van-form v-if="$route.params.id=='clbum'" @submit="onLogin">
  <van-cell-group inset>
    <van-field
      v-model="state.username"
      name="code"
      type="number"
      label="考点代码"
      placeholder="请输入考点代码"
      :rules="[{ required: true, message: '请输入考点代码' }]"
    />
    <van-field
      v-model="state.password"
      type="password"
      name="password"
      label="班主任密码"
      placeholder="请输入班主任密码"
      :rules="[{ required: true, message: '请输入班主任密码' }]"
    />
  </van-cell-group>

  <van-field name="identity"  v-model="state.identity" label="身份" v-show="false" />

  <div style="margin: 10px;">
    <van-button plain   block type="primary" native-type="submit">
      <span style="color:#555555;">点击登录</span>
    </van-button>
  </div>
</van-form>
<!-- 考点画室检索/查验 -->
<van-form v-if="$route.params.id==4" @submit="onSchoolSearch">
  <van-cell-group inset>
    <van-field
      v-model="state.username"
      name="name"
      label="名称"
      placeholder="请输入学校/画室名称"
      :rules="[{ required: true, message: '请输入学校/画室名称' }]"
    />
  </van-cell-group>
  <div style="margin: 10px;">
    <div>
      <router-link to="/?out=1">
        <van-button style="width:48%;" plain type="primary" size="normal">
          <span style="color:#555555;">返回系统登录页</span>
        </van-button>
      </router-link>

    <van-button plain style="width:48%;margin-left:4%"  type="primary" size="normal" native-type="submit">
      <span style="color:#555555;">点击查询</span>
    </van-button>

     
    </div>
  </div>
  <div>
    <van-notice-bar wrapable color="#1989fa" background="#ecf9ff" left-icon="info-o">
      可输入学校/画室名称或简称.<br/>
      未查询到的均非参加正规质检考试的机构,请考生注意,以免上当受骗.
    </van-notice-bar>

    <div v-for="(item, index) in state.list" :key="index"  class="demo-preview">
      <div style="margin-left: 5%">
        <!-- <h1>{{ state.addId - index }}</h1> -->
        <div class="clbum-info">
          <h3>{{item.name}}</h3>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
</van-form>

<!-- 
<van-button @click="testUser" plain style="width:48%;margin-left:4%"  type="primary" size="normal" >
      <span style="color:#555555;">点击测试</span>
    </van-button> -->
</template>
<script>
import { onMounted, reactive,ref } from 'vue';
import { useRouter ,useRoute } from "vue-router";
import { login,schoolLists } from '@/apis/user';
// import { Toast } from 'vant';
import sHeader from '@/components/Header';
import { useStore } from 'vuex'
import { setLocal} from '@/utils/function'
 import axios from 'axios'
export default {
  components:{
    sHeader
  },
  setup() {
    const router = useRouter(); // 第一步
    const route = useRoute(); // 第一步
    const store = useStore();
    const active = ref(0);
    const state = reactive({
      show:false,
      identity:route.params.id,
      reg0:0,
      reg1:1,
      code: '',
      password: '',
      list:{},
      user:{}
    });

    onMounted(()=>{
      if(Object.keys(route.query).indexOf('student_num')!=-1){//如果有考生考号,tabs切换已注册考生,并且考号赋值
        active.value=1;
        state.username=route.query.student_num;
      }
    })

    const testUser=()=>{
      console.log(store.state.user);
    }

    const onLogin =  async (values) => {//主考  班主任登录用同一个函数....
      values.open_id = localStorage.getItem('open_id');
      state.user = await login(values);
      setLocal('user', JSON.stringify(state.user));
      setLocal('token', state.user.authorization);
      store.commit("setUserInfo",state.user);
      axios.defaults.headers['Authorization'] = localStorage.getItem('token')

      if(state.identity=='school'){
        if(state.user.status=="1"){//已经激活直接跳转管理界面
          router.push({ path: '/dashboard'});
        }else{//跳转完善资料页面
          router.push({ path: '/perfect-info',query:{id:1}});
        }
      }else if(state.identity=='clbum'){//班主任登录
          router.push({ path: '/dashboard'});
      }else{//考生登录
        console.log(values.reg);
        if(values.reg==1){
          router.push({ path: "/dashboard"});//已注册考生
        }else{
          console.log(state.user);//未注册用户,登录获取的班级信息
          // router.push({ path: "/dai-enroll",query:{clbum_id:clbum_id,type:'dai'} });
          router.push({ path: "/dai-enroll",query:{clbum_id:state.user.id,type:'create'} });//未注册考生
        }
      }
      // window.location.href = '/#/perfect-info'
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
    };

    const onSchoolSearch =  async (values) => {//画室登录
      state.list = await schoolLists(values);
      console.log(state.list);
      // window.location.href = '/#/perfect-info'
      // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
    };

    return {
      state,
      onLogin,
      onSchoolSearch,
      active,
      testUser
    };
  },
};
</script>
<style  scoped>
.clbum-info {
  float: left;
  margin-left: 5%;
  font-size: 13px;
  width: 80%;
}
.demo-preview {
  margin:10px;
  border:1px solid #1989fa;
  background: #ffff;
  height: 6em;
}
.clbum-share {
  float: right;
  margin-right: 3%;
}
</style>